import { clientSideAvailable } from '@/helpers/checkClientSideAvailable';
import { IAuthResponse } from '@/interfaces/auth.interface';
import { GetServerSidePropsContext } from 'next';
import { PATH_NAME } from '../configs/pathName';
import cookieCommons, {
  EAuthCookiesKey,
  EUserIdTypeKeys,
} from './cookieCommons';

/** This is a legacy function, use only to makesure every user remove this cookies, dont know when to finish that process :D */
export const clearTMSCookies = () => {
  cookieCommons.deleteCookie('tms_accessToken');
  cookieCommons.deleteCookie('tms_refreshToken');
};

export const setAuthCookies = (props: IAuthResponse) => {
  const { token, refreshTokenExpiryTime, refreshToken } = props;

  cookieCommons.setAccessToken(token, {
    expires: new Date(refreshTokenExpiryTime),
  });
  cookieCommons.setRefreshToken(refreshToken, {
    expires: new Date(refreshTokenExpiryTime),
  });
};

export const setUserInfoCookies = (props: IAuthResponse) => {
  cookieCommons.setQbankUserId(props.qBankUserId.toString(), {
    expires: new Date(props.refreshTokenExpiryTime),
  });
  cookieCommons.setTmsUserId(props.userId.toString(), {
    expires: new Date(props.refreshTokenExpiryTime),
  });
  cookieCommons.setUserEmail(props.email, {
    expires: new Date(props.refreshTokenExpiryTime),
  });
};

export const serverSideIsLoggedIn = (context: GetServerSidePropsContext) => {
  if (context) {
    cookieCommons.setContext(context);
  }

  const userId = cookieCommons.getQbankUserId();
  const accessToken = cookieCommons.getAccessToken();

  return {
    isLoggedIn: !!userId && !!accessToken,
    accessToken,
    userId,
  };
};

export const clearCookiesAndLocalStorage = (
  _context?: GetServerSidePropsContext
) => {
  cookieCommons.deleteCookie(EAuthCookiesKey.Token);
  cookieCommons.deleteCookie(EAuthCookiesKey.Refresh);
  cookieCommons.deleteCookie(EUserIdTypeKeys.Qbank);
  cookieCommons.deleteCookie(EUserIdTypeKeys.Tms);

  if (clientSideAvailable()) {
    localStorage.clear();
  }

  // legacy
  cookieCommons.deleteCookie('isVerify');
  cookieCommons.deleteCookie('fullname');
  cookieCommons.deleteCookie('userAvatar');
  clearTMSCookies();
};

export const logout = () => {
  clearCookiesAndLocalStorage();
  window.location.href = PATH_NAME.LIBRARY;
};
