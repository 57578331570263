import useGetDetailSubscriptionPlan from '@/hooks/subscription/useGetDetailSubscriptionPlan';
import useGetLatestSubscription from '@/hooks/subscription/useGetLatestSubscription';
import useToggle from '@/hooks/useToggle';
import { EUserSubscriptionStatus } from '@/interfaces/subscription.interface';
import dynamic from 'next/dynamic';

const ExpiredSubscriptionModal = dynamic(
  () => import('./ExpiredSubscriptionModal')
);

export const ExpiredSubscriptionManualHOC = () => {
  const [open, toggle] = useToggle();

  const { data: latestSubscription } = useGetLatestSubscription({
    opt: {
      onSuccess(data) {
        if (
          data.subscription?.status ===
          EUserSubscriptionStatus.ExpiredWithReminder
        ) {
          toggle();
        }
      },
    },
  });

  return (
    <>
      {open && (
        <ExpiredSubscriptionModal
          open={open}
          toggle={toggle}
          packageName={latestSubscription?.subscription?.packageName}
          planDuration={latestSubscription?.subscription?.durationName}
          subscriptionId={
            latestSubscription?.subscription?.subscriptionId as string
          }
        />
      )}
    </>
  );
};

export const ExpiredSubscriptionSocketHOC = ({
  planId,
  subscriptionId,
  setExpiredSubscription,
}: {
  planId: string;
  subscriptionId: string;
  setExpiredSubscription: React.Dispatch<
    React.SetStateAction<{
      planId: string | null;
      subscriptionId: string | null;
    }>
  >;
}) => {
  const { data: plan } = useGetDetailSubscriptionPlan({ planId });

  return !planId ? null : (
    <ExpiredSubscriptionModal
      open={true}
      toggle={() =>
        setExpiredSubscription({
          planId: null,
          subscriptionId: null,
        })
      }
      packageName={plan?.packageName}
      planDuration={plan?.durationName}
      subscriptionId={subscriptionId}
    />
  );
};
