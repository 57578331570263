import { NextRouter } from 'next/router';
import { postOnLinkClick } from '../apis/tms/share';
import { PATH_NAME } from '../configs/pathName';

export const generateInviteLink = (folder: string) => {
  const origin = window.location.origin;
  return `${origin}/?folder=${folder}`;
};

export default async function redirectToFolderPage(router: NextRouter) {
  const { folder } = router.query;
  if (!folder) return false;
  const inviteLink = generateInviteLink(folder as string);
  try {
    const res = (await postOnLinkClick(inviteLink)) as any;
    router.replace(`${PATH_NAME.MY_GROUPS}/${res.folderId}`);
    return true;
  } catch (error) {
    router.replace(PATH_NAME.NOT_FOUND);
    return true;
  }
}
