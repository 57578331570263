import { generateQuery } from '@/helpers/pathName';
import { getCurrentUser } from 'apis/tms/user.api';
import cookieCommons from 'common/cookieCommons';
import { PATH_NAME } from 'configs/pathName';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

const useEnsureFillInfo = (isPageRouting: boolean) => {
  const router = useRouter();

  const isDataFillFilled = useRef<boolean>(false);

  const check = async () => {
    const accessToken = cookieCommons.getAccessToken();

    if (!accessToken) {
      return;
    }

    try {
      const data = await getCurrentUser({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!data.phoneNumber || !data.phoneNumberConfirmed) {
        if (router.pathname !== PATH_NAME.COMPLETE_INFO_PHONE)
          return router.replace({
            pathname: PATH_NAME.COMPLETE_INFO_PHONE,
            query: generateQuery(router),
          });
        return;
      }

      if (!data.email || !data.doccenFullName) {
        if (router.pathname !== PATH_NAME.COMPLETE_INFO_EMAIL) {
          return router.replace({
            pathname: PATH_NAME.COMPLETE_INFO_EMAIL,
            query: generateQuery(router),
          });
        }
        return;
      }

      isDataFillFilled.current = true;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isDataFillFilled.current && !isPageRouting) {
      check();
    }
  }, [isPageRouting]);
};

export default useEnsureFillInfo;
