export enum ESubscriptionPackage {
  Advanced = 'Advanced',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}

export enum ESubscriptionDuration {
  ThreeDays = 'ThreeDays',
  OneMonth = 'OneMonth',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
}

export const hashSubscriptionDuration: {
  [key in ESubscriptionDuration]: string;
} = {
  [ESubscriptionDuration.ThreeDays]: '3 ngày',
  [ESubscriptionDuration.OneMonth]: '1 tháng',
  [ESubscriptionDuration.SixMonths]: '6 tháng',
  [ESubscriptionDuration.OneYear]: '12 tháng',
};

export const hashSubscriptionDurationToDates: {
  [key in ESubscriptionDuration]: number;
} = {
  [ESubscriptionDuration.ThreeDays]: 3,
  [ESubscriptionDuration.OneMonth]: 31,
  [ESubscriptionDuration.SixMonths]: 31 * 6,
  [ESubscriptionDuration.OneYear]: 365,
};

export const hashSubscriptionDurationForTracking: {
  [key in ESubscriptionDuration]: string;
} = {
  [ESubscriptionDuration.ThreeDays]: '3d',
  [ESubscriptionDuration.OneMonth]: '1m',
  [ESubscriptionDuration.SixMonths]: '6m',
  [ESubscriptionDuration.OneYear]: '12m',
};

export const reversedHashSubscriptionDuration: {
  [key: string]: ESubscriptionDuration;
} = {
  '3 ngày': ESubscriptionDuration.ThreeDays,
  '1 tháng': ESubscriptionDuration.OneMonth,
  '6 tháng': ESubscriptionDuration.SixMonths,
  '12 tháng': ESubscriptionDuration.OneYear,
};

export enum EUserSubscriptionStatus {
  Active = 'Active',
  Expired = 'Expired',
  ExpiredWithReminder = 'ExpiredWithReminder',
}

export interface ISubscriptionHeading extends ISubscriptionMainContent {}

export interface ISubscriptionMainContent {
  highlightPlan: ESubscriptionPackage;
}

export interface ISubscriptionTableRow {
  benefit: React.ReactNode | string;
  tooltip?: string;
  highlightBackground: boolean;
  benefitEnableValues: ESubscriptionPackage[];
  highlightPlan: ESubscriptionPackage;
  lastRow: boolean;
}

export interface ISubscriptionPlansResponse {
  packages: {
    packageName: ESubscriptionPackage;
    plans: {
      durationName: string;
      planId: string;
      price: number;
    }[];
  }[];
}

export interface ILastestSubscriptionPlanResponse {
  subscription: {
    durationName: string;
    endDate: string;
    packageName: ESubscriptionPackage;
    startDate: string;
    status: EUserSubscriptionStatus;
    subscriptionId: string;
    subscriptionResources: string[];
  } | null;
}

export interface ISubscriptionPlanShortInformation {
  id: string;
  durationName: string;
  packageName: ESubscriptionPackage;
}

export interface ISubscriptionModalInformation {
  id: string;
  durationName: ESubscriptionDuration;
  packageName: ESubscriptionPackage;
  price: number;
}

export interface ISubscriptionBenefit {
  document: {
    maxPageCountForAI: number;
    maxFileSizeForAI: number;
    accessToDocument: boolean;
  };
  course: {
    accessToCourse: boolean;
  };
  collection: {
    accessToCollection: boolean;
  };
}
